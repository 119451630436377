export const BASENAME = '/gradient-able';
export const BASE_URL = '/app/dashboard/analytics';
export const BASE_TITLE = ' | Gradient Able Premium React + Redux Admin Template';

export const CONFIG = {
    layout: 'hidden', // vertical, horizontal,hidden
    subLayout: '', // horizontal-2
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'header-info', // header-blue, header-red, header-purple, header-info, header-green header-dark
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: true,
    boxLayout: false,
    showSearchResults: false,
    searchText: "",
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: "AIzaSyC9m6rMXs8PKHkJaT761AupFQdmcjQDwSY",
        authDomain: "gradient-able-react-hook.firebaseapp.com",
        projectId: "gradient-able-react-hook",
        storageBucket: "gradient-able-react-hook.appspot.com",
        messagingSenderId: "787384589233",
        appId: "1:787384589233:web:2b57c391ac41d2d1967b90",
        measurementId: "G-1D6ER7YWLL"
    },
    auth0: {
        client_id: 'CkaKvwheIhIQkybjTEQwN7ikcdHObsPh',
        domain: 'dev-w0-vxep3.us.auth0.com'
    },
    EntityTypes: {
        REGISTER: 3,
        UNIVERSE: 2,
        ENTITTY: 9,
        FRAMEWORK: 1
    }
};

export const ENTITY_TYPES = {
    1: {
        name: "Framework",

    },
    2: {
        name: "Universe"
    },
    3: {
        name: "Register"
    },
    4: {
        name: "User"
    },
    5: {
        name: "Role"
    },
    6: {
        name: "User Group"
    },
    7: {
        name: "Permission Schema"
    },
    8: {
        name: "User Group"
    },
    9: {
        name: "Entity"
    },
    10: {
        name: "Framework Properties"
    },
    11: {
        name: "Client Configuration"
    },
    12: {
        name: "Table"
    },
    13: {
        name: "Table Template"
    },
    14: {
        name: "Entity Workflow"
    },
    15: {
        name: "Categories"
    },
    16: {
        name: "System Matrix"
    },
    17: {
        name: "Generic View"
    },
    18: {
        name: "Entity View"
    },
    19: {
        name: "Register View"
    },
    20: {
        name: "Landing Page"
    },
    21: {
        name: "My Views"
    },
    22: {
        name: "Business Calender"
    },
    23: {
        name: "Dashboard"
    },
    24: {
        name: "Widget"
    },
    25: {
        name: "Notification Template"
    }
    
}